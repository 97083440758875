import { LoadingButton } from '@mui/lab';
import { Grid, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { TextFieldNumber } from 'components/common';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { paperService } from 'services';

type Props = {
  answer: TestPaperResultAnswer;
  onSaveMark: (addMark: number) => void;
};

const PaperResultMark = ({ answer, onSaveMark }: Props) => {
  const [mark, setMark] = useState(0.0);
  const { control, handleSubmit, reset } = useForm<TestPaperResultAnswerMarkBody>();

  useEffect(() => {
    reset({
      comment: answer.mentorComment ?? '',
      point: answer.point ?? answer.maxPoint,
    });
    setMark(answer.point ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer]);

  const {
    mutate: updateMark,
    variables: payload,
    isLoading,
  } = useMutation(paperService.putPaperResultAnswerMark, {
    onSuccess: () => {
      onSaveMark((payload?.point ?? 0) - mark);
      setMark(payload?.point ?? 0);
      enqueueSnackbar('Câp nhật điểm cho câu hỏi thành công');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      updateMark({
        ...values,
        id: answer.id,
      });
    })();
  };

  return (
    <>
      <Grid container spacing={4} className='mt-4'>
        <Grid item md={6} xs={12}>
          <Controller
            name='point'
            defaultValue={answer.point ?? answer.maxPoint}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldNumber
                {...field}
                fullWidth
                label='Điểm'
                error={!!error}
                helperText={error?.message}
                inputProps={{ decimalScale: 2 }}
              />
            )}
          />
          {answer.maxPoint && (
            <Typography variant='inherit' color='primary' className='mt-1'>
              Điểm tối đa {answer.maxPoint} điểm
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Controller
            name='comment'
            defaultValue={answer.mentorComment ?? ''}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                multiline
                rows={4}
                fullWidth
                label='Nhận xét'
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            variant='contained'
            size='small'
            color='success'
            loading={isLoading}
            onClick={handleClickSubmit}
          >
            Lưu lại
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
};

export default PaperResultMark;
