import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { formatDateTime, formatNumber } from 'utils/common';
import { Link } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { paperService } from 'services';
import { useSearch } from 'hooks';
import { privateRoute } from 'routes';

type Props = PopupController & {
  paperAssignId: number;
};
const PopupMyPaperResultList = ({ paperAssignId, onClose }: Props) => {
  const { dataSearch, onPaginationChange } = useSearch();
  const { data, isFetching } = useQuery(
    [`paperService.fetchMyPaperResults_${paperAssignId}`, dataSearch],
    () =>
      paperService.fetchMyPaperResults({
        ...dataSearch,
        id: paperAssignId,
      }),
    { keepPreviousData: true },
  );
  const { list: items = [], total = 0 } = data ?? {};

  return (
    <>
      <DialogTitle>Lịch sử làm bài test</DialogTitle>

      <DialogContent>
        <DataGrid
          loading={isFetching}
          getRowId={(row) => row.id}
          getRowHeight={() => 64}
          rows={items}
          rowCount={total}
          onPaginationModelChange={onPaginationChange}
          columns={[
            {
              field: 'createdAt',
              headerName: 'Thời gian làm bài',
              flex: 1,
              minWidth: 200,
              sortable: false,
              renderCell: ({ row }) => (
                <>
                  <div>{formatDateTime(row.createdAt)}</div>
                </>
              ),
            },
            {
              field: 'savedAt',
              headerName: 'Thời gian kết thúc',
              flex: 1,
              minWidth: 200,
              sortable: false,
              renderCell: ({ row }) => (
                <>
                  <div>{row.savedAt ? formatDateTime(row.savedAt) : ''}</div>
                </>
              ),
            },
            {
              field: 'point',
              headerName: 'Điểm',
              minWidth: 240,
              sortable: false,
              renderCell: ({ row }) => (
                <>
                  <div>
                    {formatNumber(row.point)}/{formatNumber(row.maxPoint)}
                  </div>
                </>
              ),
            },
            {
              field: 'action',
              headerName: 'Hành động',
              minWidth: 240,
              sortable: false,
              renderCell: ({ row }) => (
                <div>
                  <Link
                    to={privateRoute.studentPaperResultView.url({ id: row.id })}
                    target='_blank'
                    className='text-primary-main hover:text-primary-dark'
                  >
                    <Button size='small' color='info'>
                      Chi tiết
                    </Button>
                  </Link>
                </div>
              ),
            },
          ]}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupMyPaperResultList;
