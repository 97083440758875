import { Container, Dialog, Grid, List, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { paperService } from 'services';
import { PopupQuestionUpdate, PaperQuestionView } from './components';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { adminRoute } from 'routes';

const PaperView = () => {
  const navigate = useNavigate();
  const [openAdd, setOpenAdd] = useState(false);
  const { id } = useParams();

  const { data, isSuccess } = useQuery(
    ['paperService.fetchPaperQuestions'],
    () => paperService.fetchPaperQuestions({ id: +id!, size: -1 }),
    {
      keepPreviousData: true,
    },
  );

  const { data: paper } = useQuery(['paperService.getPaperInfo'], () => paperService.getPaperInfo({ id: +id! }), {
    keepPreviousData: true,
  });
  const { list: items = [] } = data ?? {};

  const backList = () => {
    navigate(adminRoute.paperList.path);
  };
  return (
    <Container maxWidth='xl' className='py-6'>
      <Grid container spacing={3}>
        {isSuccess && (
          <Grid item xs={12}>
            <Typography variant='h4' color='primary' className='mb-6 space-x-3'>
              <span>Danh sách câu hỏi</span>
              <LoadingButton
                color='primary'
                variant='contained'
                onClick={() => {
                  setOpenAdd(true);
                }}
              >
                Thêm câu hỏi
              </LoadingButton>
            </Typography>
            <List>
              {paper &&
                items.map((question, index) => (
                  <div key={index} className='mt-2'>
                    <PaperQuestionView paper={paper} questionIndex={index + 1} question={question} />
                  </div>
                ))}
            </List>
            {items.length === 0 && <div className='text-center font-bold'>BÀI TEST CHƯA CÓ CÂU HỎI NÀO</div>}
          </Grid>
        )}
      </Grid>
      {paper && (
        <Dialog maxWidth='xl' open={openAdd}>
          <PopupQuestionUpdate paper={paper} onClose={() => setOpenAdd(false)} />
        </Dialog>
      )}
      <div className='mt-3 flex justify-center'>
        <LoadingButton variant='contained' onClick={backList}>
          Quay lại
        </LoadingButton>
      </div>
    </Container>
  );
};

export default PaperView;
