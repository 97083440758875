import { Clear } from '@mui/icons-material';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useQuery } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { Controller, useForm } from 'react-hook-form';
import { categoryService } from 'services';

type Props = {
  onChange: (...args: any) => void;
};

const PaperSearch = ({ onChange }: Props) => {
  const { control, handleSubmit, reset, watch } = useForm<TestQuestionSearchParams>();

  const parentTopicId = watch('parentTopicId');
  const { data: dataParentTopic } = useQuery(['categoryService.getAllTestQuestionParentTopics'], () =>
    categoryService.getAllTestQuestionTopics({ parentId: '' }),
  );

  const { data: dataTopic } = useQuery(
    ['categoryService.getAllTestQuestionTopics', { parentId: parentTopicId }],
    () => categoryService.getAllTestQuestionTopics({ parentId: parentTopicId }),
    {
      enabled: !!parentTopicId,
    },
  );

  const { data: dataLevel } = useQuery(['categoryService.getAllQuestionLevels'], () =>
    categoryService.getAllQuestionLevels(),
  );

  const handleClickSearch = () => {
    handleSubmit((values) => {
      onChange(values);
    })();
  };

  const handleClickClear = () => {
    reset({
      parentTopicId: '',
      topicId: '',
      questionType: '',
      content: '',
      createdAtFrom: null,
      createdAtTo: null,
    });
    handleClickSearch();
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={3} xs={12}>
          <Controller
            name='parentTopicId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Chủ đề cha' error={!!error} helperText={error?.message}>
                {(dataParentTopic?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='topicId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Chủ đề con' error={!!error} helperText={error?.message}>
                {(dataTopic?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <Controller
            name='questionType'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Loại câu hỏi' error={!!error} helperText={error?.message}>
                {(dataLevel?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.code}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <Controller
            name='createdAtFrom'
            defaultValue={null}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                format='dd/MM/yyyy'
                slotProps={{
                  textField: {
                    fullWidth: true,
                    label: 'Ngày tạo từ',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <Controller
            name='createdAtTo'
            defaultValue={null}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                format='dd/MM/yyyy'
                slotProps={{
                  textField: {
                    fullWidth: true,
                    label: 'Ngày tạo đến',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name='content'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} fullWidth label='Nội dung' error={!!error} helperText={error?.message} />
            )}
          />
        </Grid>
      </Grid>
      <div className='mt-6 flex justify-end space-x-3'>
        <Button onClick={handleClickSearch}>Tìm kiếm</Button>
        <Button variant='outlined' color='inherit' onClick={handleClickClear}>
          <Clear />
        </Button>
      </div>
    </>
  );
};

export default PaperSearch;
