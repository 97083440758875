import { Button, CardMedia, Chip, Dialog, Paper, Tooltip, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { API_URL } from 'env';
import { formatDateTime, formatNumber } from 'utils/common';
import { privateRoute } from 'routes';
import PopupMyPaperResultList from './PopupMyPaperResultList';
import { useState } from 'react';
import { paperService } from 'services';

type Props = {
  paper: TestPaper;
};

const CardPaper = ({ paper }: Props) => {
  const navigate = useNavigate();
  const [openHistory, setOpenHistory] = useState(false);
  const handleClickTesting = () => {
    paperService.getTesting({ paperId: paper.id, paperAssignId: paper.paperAssignId }).then((res) => {
      navigate(privateRoute.studentTesting.url({ id: res.id }));
    });
  };
  return (
    <>
      <Paper
        variant='outlined'
        className='flex justify-between rounded-xl p-4 mobile:flex-col mobile:space-y-2 md:space-x-4'
      >
        <div className='flex mobile:flex-col mobile:items-stretch mobile:space-y-4 md:space-x-4'>
          <CardMedia
            image={`${API_URL}/api/v1/files/public/testing.jpeg`}
            className='h-[180px] min-w-[240px] rounded-lg border bg-contain hover:shadow-md mobile:w-full'
          >
            <div></div>
          </CardMedia>
          <div className='space-y-2'>
            <Typography variant='h4' className='hover:text-primary-main'>
              {paper.name}
            </Typography>
            <div>
              <span className='text-black/60'>Số lượng câu hỏi: </span>
              {formatNumber(paper.totalQuestion)} câu
            </div>
            {!paper.result && (
              <div>
                <span className='text-black/60'>Số lần test tối đa: </span>
                {paper.maxTime ? <>{formatNumber(paper.maxTime)} lần</> : <>Không giới hạn</>}
              </div>
            )}
            {paper.expiredAt && (
              <div>
                <span className='text-black/60'>Thời hạn: </span>
                {formatDateTime(paper.expiredAt)}
              </div>
            )}
            {paper.result && (
              <>
                <div>
                  <span className='text-black/60'>Đã làm: </span>
                  {paper.maxTime
                    ? formatNumber(paper.numberTested) + '/' + formatNumber(paper.maxTime)
                    : formatNumber(paper.numberTested)}{' '}
                  lần
                </div>
                <div>
                  <Typography variant='h5'>
                    Điểm: {formatNumber(paper.result.point)}/{formatNumber(paper.result.maxPoint)}
                  </Typography>
                </div>
                <div>
                  <span className='text-black/60'>xếp loại: </span>
                  {paper.result.assessmentType?.name}
                </div>
              </>
            )}
          </div>
        </div>
        <div className='flex flex-col items-end justify-between'>
          <div className='space-y-2'>
            <div className='flex justify-end'>
              <Chip
                label={paper.result ? 'Đã thực hiện' : 'Chưa thực hiện'}
                color={paper.result ? 'primary' : 'success'}
              />
            </div>
          </div>
          <div className='mt-6 flex space-x-2'>
            {paper.result && (
              <>
                <Tooltip title='Xem thông tin chi tiết bài làm'>
                  <Link to={privateRoute.studentPaperResultView.url({ id: paper.result.id })}>
                    <Button className='whitespace-nowrap' size='small' color='success'>
                      Chi tiết
                    </Button>
                  </Link>
                </Tooltip>
                <Tooltip title='Lịch sử làm bài test'>
                  <Button
                    className='whitespace-nowrap'
                    size='small'
                    color='secondary'
                    onClick={() => setOpenHistory(true)}
                  >
                    Lịch sử
                  </Button>
                </Tooltip>
              </>
            )}
            <Tooltip title='Làm bài test'>
              <Button className='whitespace-nowrap' size='small' color='primary' onClick={handleClickTesting}>
                Làm bài
              </Button>
            </Tooltip>
          </div>
        </div>
      </Paper>
      <Dialog maxWidth='md' open={openHistory}>
        <PopupMyPaperResultList paperAssignId={paper.paperAssignId} onClose={() => setOpenHistory(false)} />
      </Dialog>
    </>
  );
};

export default CardPaper;
