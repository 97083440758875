import { Clear } from '@mui/icons-material';
import { Button, Grid, MenuItem } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { Controller, useForm } from 'react-hook-form';
import { categoryService } from 'services';

type Props = {
  paperId: number;
  onChange: (...args: any) => void;
};

const PaperQuestionSearch = ({ paperId, onChange }: Props) => {
  const { control, handleSubmit, reset, watch } = useForm<TestPaperQuestionSearchParams>();
  const parentTopicId = watch('parentTopicId');
  const { data: dataParentTopic } = useQuery(['categoryService.getAllTestQuestionParentTopics'], () =>
    categoryService.getAllTestQuestionTopics({ parentId: '' }),
  );

  const { data: dataTopic } = useQuery(
    ['categoryService.getAllTestQuestionTopics', { parentId: parentTopicId }],
    () => categoryService.getAllTestQuestionTopics({ parentId: parentTopicId }),
    {
      enabled: !!parentTopicId,
    },
  );

  const { data: dataLevel } = useQuery(['categoryService.getAllQuestionLevels'], () =>
    categoryService.getAllQuestionLevels(),
  );

  const handleClickSearch = () => {
    handleSubmit((values) => {
      onChange({ ...values, paperId: paperId });
    })();
  };

  const handleClickClear = () => {
    reset({
      parentTopicId: '',
      topicId: '',
      level: '',
    });
    handleClickSearch();
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={3} xs={12}>
          <Controller
            name='parentTopicId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Chủ đề cha' error={!!error} helperText={error?.message}>
                {(dataParentTopic?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='topicId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Chủ đề con' error={!!error} helperText={error?.message}>
                {(dataTopic?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='topicId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Mức độ' error={!!error} helperText={error?.message}>
                {(dataLevel?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.code}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={3} xs={12} className='mt-2'>
          <Button onClick={handleClickSearch}>Tìm kiếm</Button>
          <Button variant='outlined' color='inherit' onClick={handleClickClear}>
            <Clear />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default PaperQuestionSearch;
