import { Box, Container, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { CardPaperList } from './components';

const PaperListOwner = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Container maxWidth='lg' className='py-6'>
      <Box
        sx={{
          flexGrow: 10,
          maxWidth: '100%',
          display: 'flex',
          bgcolor: 'background.paper',
        }}
      >
        <Tabs
          value={activeTab}
          onChange={(event, value) => setActiveTab(value)}
          variant='scrollable'
          scrollButtons='auto'
        >
          <Tab label='TẤT CẢ' />
          <Tab label='HOÀN THÀNH' />
        </Tabs>
      </Box>
      <div className='py-6'>
        {activeTab === 0 && <CardPaperList />}
        {activeTab === 1 && <CardPaperList testStatus={1} />}
      </div>
    </Container>
  );
};

export default PaperListOwner;
