import { Container, Grid, IconButton, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { paperService } from 'services';
import { ResultQuestionView } from './components';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { formatDateTime, formatNumber } from 'utils/common';
import { privateRoute, publicRoute } from 'routes';
import { CheckCircle, Close, HourglassBottom, HourglassEmpty } from '@mui/icons-material';

type InfoProps = {
  label: React.ReactNode;
  value?: React.ReactNode;
};

const GridInfo = ({ label, value }: InfoProps) => {
  return (
    <div>
      <span>{label}: </span>
      <span className='font-bold'>{value ?? ''}</span>
    </div>
  );
};

const StudentPaperResultView = () => {
  const navigate = useNavigate();
  const { id, from } = useParams();
  const [currentAnswer, setCurrentAnswer] = useState<TestPaperResultAnswer | undefined>();
  const [questionAnswerId, setQuestionAnswerId] = useState(0);

  const { data } = useQuery(
    [`paperService.getPaperResultInfo_${id}`],
    () => paperService.getPaperResultInfo({ id: +id! }),
    {
      keepPreviousData: true,
    },
  );

  const { answers: items = [] } = data ?? {};

  useEffect(() => {
    if (questionAnswerId) {
      setCurrentAnswer(items.find((e) => e.id === questionAnswerId));
    } else {
      setCurrentAnswer(items[0]);
    }
  }, [items, questionAnswerId]);

  const backList = () => {
    if (!from || from === 'assign') {
      navigate(privateRoute.paper.path);
    } else {
      navigate(publicRoute.postPublicView.url({ uuid: from }));
    }
  };
  return (
    <>
      <Container maxWidth='xl' className='py-6'>
        <Grid container spacing={3}>
          <Grid item xs={12} md={10}>
            <Typography variant='h4' color='primary' className='mb-10 text-center'>
              {data?.paper?.name}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant='h4'>
                  Điểm: {formatNumber(data?.point)}/{formatNumber(data?.maxPoint)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={data?.classroom ? 6 : 12} className='mt-1'>
                <GridInfo label='Học viên' value={data?.user?.fullName} />
              </Grid>
              {data?.classroom && (
                <Grid item xs={12} md={6}>
                  <GridInfo label='Lớp học' value={data?.classroom?.code + ' - ' + data?.classroom?.name} />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <GridInfo label='Thời gian bắt đầu' value={data?.createdAt ? formatDateTime(data?.createdAt) : ''} />
              </Grid>
              <Grid item xs={12} md={6}>
                <GridInfo label='Thời gian kết thúc' value={data?.savedAt ? formatDateTime(data?.savedAt) : ''} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={10}>
            <Typography variant='h4' color='primary' className='mb-6 mt-6 text-center'>
              <span>NỘI DUNG BÀI LÀM</span>
            </Typography>
            {currentAnswer && <ResultQuestionView answer={currentAnswer} />}
          </Grid>
          <Grid item xs={12} md={2}>
            <List>
              {items.map((answer, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    sx={{
                      height: '2rem',
                      border: 0,
                      '&.Mui-selected': {
                        backgroundColor: '#2e8b57',
                        color: 'white',
                      },
                      '&.Mui-focusVisible': {
                        backgroundColor: '#eceff1',
                        color: 'white',
                      },
                      '&.Mui-selected:hover': {
                        backgroundColor: '#ddfdde',
                        color: 'black',
                      },
                      ':hover': {
                        backgroundColor: '#90caf9',
                        color: 'white',
                      },
                    }}
                    onClick={() => setQuestionAnswerId(answer.id)}
                    selected={answer.id === currentAnswer?.id}
                  >
                    {answer.questionType === 'ESSAY' ? (
                      <ListItemText primary={'Câu ' + (index + 1) + ' (TL)'} />
                    ) : (
                      <ListItemText primary={'Câu ' + (index + 1)} />
                    )}
                  </ListItemButton>
                  <div className='items-end'>
                    {answer.isCorrect === -1 && (
                      <IconButton edge='end' aria-label='Câu hỏi chưa làm' title='Câu hỏi chưa làm' color='warning'>
                        <HourglassEmpty />
                      </IconButton>
                    )}
                    {answer.isCorrect === 0 && (
                      <IconButton edge='end' aria-label='Câu hỏi làm sai' title='Câu hỏi làm sai' color='error'>
                        <Close />
                      </IconButton>
                    )}
                    {answer.isCorrect === 1 && (
                      <IconButton
                        edge='end'
                        aria-label='Câu hỏi làm đúng một phần'
                        title='Câu hỏi làm đúng một phần'
                        color='primary'
                      >
                        <HourglassBottom />
                      </IconButton>
                    )}
                    {answer.isCorrect === 2 && (
                      <IconButton edge='end' aria-label='Câu hỏi làm đúng' title='Câu hỏi làm đúng' color='success'>
                        <CheckCircle />
                      </IconButton>
                    )}
                  </div>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
        <div className='mt-2 flex justify-center space-x-3'>
          <LoadingButton variant='contained' onClick={backList}>
            Quay lại
          </LoadingButton>
        </div>
      </Container>
    </>
  );
};

export default StudentPaperResultView;
