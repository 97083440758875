import { Box, Divider, FormControlLabel, Grid, RadioGroup, Typography } from '@mui/material';
import { Check, CheckBoxOutlineBlank, CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ViewEditorContent from './ViewEditorContent';
import { formatNumber } from 'utils/common';

type Props = {
  answer: TestPaperResultAnswer;
};

const ResultQuestionView = ({ answer }: Props) => {
  return (
    <>
      {answer.group && (
        <Grid container spacing={3} className='mt-1'>
          <Grid item xs={12} md={10} className='space-y-1'>
            <Typography variant='h5'>
              Câu hỏi {answer.group.fromQuestion} - {answer.group.toQuestion}
            </Typography>
            <Typography variant='h5' className='mt-1'>
              Ngữ cảnh đề bài
            </Typography>
            <ViewEditorContent data={answer.group.name} />
          </Grid>
          <Grid item xs={12}>
            <Divider light />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={3} className='mt-1'>
        <Grid item xs={12}>
          {answer.questionType === 'CHOICE' ? (
            <Typography variant='h5'>
              Câu hỏi {answer.sort} ({formatNumber(answer.point)} điểm)
            </Typography>
          ) : (
            <Typography variant='h5'>
              Câu hỏi {answer.sort} ({formatNumber(answer.point)} điểm) (Tự luận)
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <ViewEditorContent data={answer.questionContent} />
        </Grid>
      </Grid>

      {answer.questionType === 'CHOICE' && (
        <Grid container spacing={3} className='mb-2 mt-1'>
          <Grid item xs={12}>
            <Typography variant='h5'>Đáp án</Typography>
          </Grid>
        </Grid>
      )}

      {answer.questionType === 'CHOICE' && !answer.isMultipleChoice && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <RadioGroup>
              {answer.questionAnswers!.map((a, index) => (
                <Box border={0.5} borderColor='rgba(0, 0, 0, 0.1)' borderRadius={1} className='mt-2 p-2' key={index}>
                  <FormControlLabel
                    className='space-x-2 px-2 py-1'
                    label={<ViewEditorContent data={a.content} />}
                    control={
                      <>
                        {a.isSelected && answer.isCorrect === 2 ? (
                          <CheckCircle color='success' />
                        ) : a.isSelected && answer.isCorrect === 1 ? (
                          <CheckCircle color='warning' />
                        ) : a.isSelected ? (
                          <CheckCircle color='error' />
                        ) : a.isCorrect === 1 ? (
                          <Check color='success' />
                        ) : (
                          <RadioButtonUnchecked color='inherit' />
                        )}
                      </>
                    }
                  />
                </Box>
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
      )}
      {answer.questionType === 'CHOICE' &&
        answer.isMultipleChoice &&
        answer.questionAnswers!.map((a, index) => (
          <Grid container key={index} spacing={3}>
            <Grid item xs={12}>
              <Box border={0.5} borderColor='rgba(0, 0, 0, 0.1)' borderRadius={1} className='mt-2 p-2'>
                <FormControlLabel
                  className='space-x-2 px-2 py-1'
                  label={<ViewEditorContent data={a.content} />}
                  control={
                    <>
                      {a.isSelected && answer.isCorrect === 2 ? (
                        <CheckBoxIcon color='success' />
                      ) : a.isSelected && answer.isCorrect === 1 ? (
                        <CheckBoxIcon color='warning' />
                      ) : a.isSelected ? (
                        <CheckBoxIcon color='error' />
                      ) : a.isCorrect === 1 ? (
                        <Check color='success' />
                      ) : (
                        <CheckBoxOutlineBlank color='inherit' />
                      )}
                    </>
                  }
                />
              </Box>
            </Grid>
          </Grid>
        ))}
      {answer.questionType === 'ESSAY' && (
        <Grid container spacing={3} className='mt-1'>
          <Grid item xs={12}>
            <Typography variant='h5'>Nội dung trả lời</Typography>
          </Grid>
          <Grid item xs={12}>
            <ViewEditorContent data={answer.answerContent} />
          </Grid>
          {answer.approvedAt && answer.mentorComment && (
            <>
              <Grid item xs={12}>
                <Typography variant='h5'>Nhận xét</Typography>
              </Grid>
              <Grid item xs={12}>
                <ViewEditorContent data={answer.mentorComment} />
              </Grid>
            </>
          )}
        </Grid>
      )}
      {answer.questionHelp && (
        <>
          <Divider light className='mt-2' />
          <Grid container spacing={3} className='mt-1'>
            <Grid item xs={12}>
              <Typography variant='h5'>Giải thích</Typography>
            </Grid>
            <Grid item xs={12}>
              <ViewEditorContent data={answer.questionHelp} />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default ResultQuestionView;
