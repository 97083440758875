import { DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { formatDateTime } from 'utils/common';
import { LoadingButton } from '@mui/lab';
import { paperService } from 'services';
import { useSearch } from 'hooks';

type InfoProps = {
  label: React.ReactNode;
  value?: React.ReactNode;
};

const GridInfo = ({ label, value }: InfoProps) => {
  return (
    <div>
      <span>{label}: </span>
      <span className='font-bold'>{value ?? ''}</span>
    </div>
  );
};

type Props = PopupController & {
  paperResult: TestPaperResult;
};

const PopupPaperResultHis = ({ paperResult, onClose }: Props) => {
  const { dataSearch, onPaginationChange } = useSearch();
  const { data, isFetching } = useQuery(
    [`paperService.fetchPaperResultTracks${paperResult.id}`, dataSearch],
    () =>
      paperService.fetchPaperResultTracks({
        ...dataSearch,
        id: paperResult.id,
      }),
    { keepPreviousData: true },
  );
  const { list: items = [], total = 0 } = data ?? {};

  return (
    <>
      <DialogTitle>Lịch sử làm bài test</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={paperResult.classroom ? 6 : 12} className='mt-1'>
            <GridInfo label='Học viên' value={paperResult.user?.fullName} />
          </Grid>
          {paperResult.classroom && (
            <Grid item xs={12} md={6}>
              <GridInfo label='Lớp học' value={paperResult.classroom?.code + ' - ' + paperResult.classroom?.name} />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <GridInfo
              label='Thời gian bắt đầu'
              value={paperResult.createdAt ? formatDateTime(paperResult.createdAt) : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GridInfo
              label='Thời gian kết thúc'
              value={paperResult.savedAt ? formatDateTime(paperResult.savedAt) : ''}
            />
          </Grid>
        </Grid>
        <DataGrid
          loading={isFetching}
          getRowId={(row) => row.id}
          getRowHeight={() => 96}
          rows={items}
          rowCount={total}
          onPaginationModelChange={onPaginationChange}
          columns={[
            {
              field: 'createdAt',
              headerName: 'Thời gian làm bài',
              flex: 1,
              minWidth: 180,
              sortable: false,
              renderCell: ({ row }) => (
                <>
                  <div>{formatDateTime(row.createdAt)}</div>
                </>
              ),
            },
            {
              field: 'ip',
              headerName: 'IP',
              flex: 1,
              minWidth: 200,
              sortable: false,
              renderCell: ({ row }) => (
                <>
                  <div>{row.ip}</div>
                </>
              ),
            },
            {
              field: 'userAgent',
              headerName: 'Thông tin thiết bị',
              flex: 1,
              minWidth: 450,
              sortable: false,
              renderCell: ({ row }) => (
                <Typography sx={{ whiteSpace: 'pre-wrap' }} variant='inherit'>
                  {row.userAgent}
                </Typography>
              ),
            },
          ]}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupPaperResultHis;
