import { LoadingButton } from '@mui/lab';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TextFieldNumber, TextFieldSelect } from 'components/common';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { classroomService, courseService, queryClient } from 'services';
import { DateTime } from 'luxon';

export const ClassroomMethodTypes = [
  { value: 'BEFORE', label: 'Trước khóa học' },
  { value: 'OFFICIAL', label: 'Chính thức' },
];

type Props = PopupController & {
  classroom?: Classroom;
};

const PopupClassroomUpsert = ({ classroom, onClose }: Props) => {
  const { control, handleSubmit } = useForm<ClassroomCreateBody>();
  const { data: dataCourse } = useQuery(['courseService.fetchCourses'], () => courseService.fetchCourses());

  const { mutate: addClassroom, isLoading: isLoadingAdd } = useMutation(classroomService.addClassroom, {
    onSuccess: () => {
      enqueueSnackbar('Thêm mới lớp học thành công');
      queryClient.invalidateQueries(['classroomService.fetchClassrooms']);
      onClose();
    },
  });

  const { mutate: updateClassroom, isLoading: isLoadingUpdate } = useMutation(classroomService.updateClassroom, {
    onSuccess: () => {
      enqueueSnackbar('Câp nhật lớp học thành công');
      queryClient.invalidateQueries(['classroomService.fetchClassrooms']);
      onClose();
    },
  });

  const handleClickSubmitAdd = () => {
    handleSubmit((values) => {
      addClassroom({
        ...values,
      });
    })();
  };

  const handleClickSubmitUpdate = () => {
    handleSubmit((values) => {
      updateClassroom({
        ...values,
        id: classroom!.id,
      });
    })();
  };

  return (
    <>
      {!classroom && <DialogTitle>Thêm mới lớp học</DialogTitle>}
      {classroom && <DialogTitle>Cập nhật lớp học</DialogTitle>}

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Controller
              name='courseId'
              defaultValue={classroom?.courseId}
              control={control}
              rules={{
                required: 'Khóa học là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextFieldSelect
                  {...field}
                  required
                  fullWidth
                  label='Khóa học'
                  error={!!error}
                  helperText={error?.message}
                >
                  {(dataCourse?.list ?? []).map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextFieldSelect>
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name='code'
              defaultValue={classroom?.code}
              control={control}
              rules={{
                required: 'Mã lớp học là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label='Mã lớp học'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name='name'
              defaultValue={classroom?.name}
              control={control}
              rules={{
                required: 'Tên lớp học là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label='Tên lớp học'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name='address'
              defaultValue={classroom?.address}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField {...field} fullWidth label='Địa điểm lớp học' error={!!error} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name='startDate'
              defaultValue={classroom?.startDate ? DateTime.fromISO(classroom?.startDate) : null}
              control={control}
              rules={{
                required: 'Thời gian khai giảng của lớp học là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  format='dd/MM/yyyy'
                  slotProps={{
                    textField: {
                      required: true,
                      fullWidth: true,
                      label: 'Thời gian từ',
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name='endDate'
              defaultValue={classroom?.endDate ? DateTime.fromISO(classroom?.endDate) : null}
              control={control}
              rules={{
                required: 'Thời gian kết thúc của lớp học là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  format='dd/MM/yyyy'
                  slotProps={{
                    textField: {
                      required: true,
                      fullWidth: true,
                      label: 'Khai giảng đến',
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name='startEnrollmentDate'
              defaultValue={classroom?.startEnrollmentDate ? DateTime.fromISO(classroom?.startEnrollmentDate) : null}
              control={control}
              rules={{
                required: 'Thời gian bắt đầu tuyển sinh của lớp học là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <DateTimePicker
                  {...field}
                  format='dd/MM/yyyy HH:mm'
                  slotProps={{
                    textField: {
                      required: true,
                      fullWidth: true,
                      label: 'Thời gian bắt đầu tuyển sinh',
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name='totalSlot'
              defaultValue={classroom?.totalSlot}
              control={control}
              rules={{
                required: 'Số slot của lớp học là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextFieldNumber
                  {...field}
                  fullWidth
                  required
                  label='Số slot'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name='zaloLink'
              defaultValue={classroom?.zaloLink}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label='Link group Zalo chính thức'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name='zaloLinkFreeChat'
              defaultValue={classroom?.zaloLinkFreeChat}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label='Link group Zalo buôn chuyện'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='googleMeetLink'
              defaultValue={classroom?.googleMeetLink}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField {...field} fullWidth label='Link Google meet' error={!!error} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name='studyTime'
              defaultValue={classroom?.studyTime}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField {...field} fullWidth label='Ngày học' error={!!error} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name='schedule'
              defaultValue={classroom?.schedule}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField {...field} fullWidth label='Thời gian học' error={!!error} helperText={error?.message} />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        {!classroom && (
          <LoadingButton variant='contained' loading={isLoadingAdd} onClick={handleClickSubmitAdd}>
            Thêm mới
          </LoadingButton>
        )}
        {classroom && (
          <LoadingButton variant='contained' loading={isLoadingUpdate} onClick={handleClickSubmitUpdate}>
            Cập nhật
          </LoadingButton>
        )}
      </DialogActions>
    </>
  );
};

export default PopupClassroomUpsert;
