import { client } from './axios';

const fetchPapers = (body: TestPaperSearchParams): Promise<PaginateResponse<TestPaper>> =>
  client.post(`/api/v1/tests/papers/list`, body);
const fetchAllPapers = (body: TestPaperSearchParams): Promise<PaginateResponse<TestPaper>> =>
  client.post(`/api/v1/tests/papers/all`, body);
const fetchMyPapers = (body: TestMyPaperSearchParams): Promise<PaginateResponse<TestPaper>> =>
  client.post(`/api/v1/tests/papers/me/list`, body);
const getPaperInfo = ({ id }: ByID): Promise<TestPaper> => client.get(`/api/v1/tests/papers/${id}`);
const addPaper = (body: PaperCreateBody): Promise<ByID> => client.post(`/api/v1/tests/papers`, body);
const updatePaper = ({ id, ...body }: PaperUpdateBody): Promise<SuccessResponse> =>
  client.put(`/api/v1/tests/papers/${id}`, body);
const putPaperActive = ({ id }: ByID): Promise<SuccessResponse> => client.put(`/api/v1/tests/papers/${id}/activate`);
const putPaperDeactive = ({ id }: ByID): Promise<SuccessResponse> =>
  client.put(`/api/v1/tests/papers/${id}/deactivate`);
const fetchPaperQuestions = ({ id, ...body }: ByID & CommentSearchParams): Promise<PaginateResponse<TestQuestion>> =>
  client.post(`/api/v1/tests/papers/${id}/questions/list`, body);
const searchPaperQuestions = (body: TestPaperQuestionSearchParams): Promise<PaginateResponse<TestQuestion>> =>
  client.post(`/api/v1/tests/questions/list`, body);
const addPaperQuestions = ({ id, ...body }: TestPaperQuestionsAddBody): Promise<SuccessResponse> =>
  client.post(`/api/v1/tests/papers/${id}/questions`, body);
const postMovePaperQuestionUp = ({ id, ...body }: TestPaperQuestionRemoveBody): Promise<SuccessResponse> =>
  client.post(`/api/v1/tests/papers/${id}/questions/up`, body);
const postMovePaperQuestionDown = ({ id, ...body }: TestPaperQuestionRemoveBody): Promise<SuccessResponse> =>
  client.post(`/api/v1/tests/papers/${id}/questions/down`, body);
const putPaperQuestionPoint = ({ id, ...body }: TestPaperQuestionPointUpdateBody): Promise<SuccessResponse> =>
  client.put(`/api/v1/tests/papers/${id}/questions/point`, body);
const deletePaperQuestion = ({ id, ...body }: TestPaperQuestionRemoveBody): Promise<SuccessResponse> =>
  client.post(`/api/v1/tests/papers/${id}/questions/delete`, body);

const fetchPaperAssigns = ({ ...body }: TestPaperAssignSearchParams): Promise<PaginateResponse<TestPaperAssign>> =>
  client.post(`/api/v1/tests/papers/assigns/list`, body);
const addPaperAssign = ({ ...body }: TestPaperAssignAddBody): Promise<SuccessResponse> =>
  client.post(`/api/v1/tests/papers/assigns`, body);
const removePaperAssign = ({ id, paperId }: TestPaperAssignRemoveBody): Promise<SuccessResponse> =>
  client.delete(`/api/v1/tests/papers/${paperId}/assigns/${id}`);

const getPaperAssesment = ({ id }: ByID): Promise<TestPaperAssessment> =>
  client.get(`/api/v1/tests/papers/${id}/assessments`);
const postPaperAssessment = ({ id, ...body }: TestPaperAssessmentUpdateBody): Promise<SuccessResponse> =>
  client.post(`/api/v1/tests/papers/${id}/assessments`, body);

const fetchPaperResults = (body: TestPaperResultSearchParams): Promise<PaginateResponse<TestPaperResult>> =>
  client.post(`/api/v1/tests/paper-results/list`, body);
const getPaperResultInfo = ({ id }: ByID): Promise<TestPaperResult> => client.get(`/api/v1/tests/paper-results/${id}`);
const fetchPaperResultAnswers = ({ id }: ByID): Promise<PaginateResponse<TestPaperResultAnswer>> =>
  client.post(`/api/v1/tests/paper-results/${id}/answers`);
const putPaperResultAnswerMark = ({ id, ...body }: TestPaperResultAnswerMarkBody): Promise<SuccessResponse> =>
  client.put(`/api/v1/tests/paper-results/answers/${id}/mark`, body);
const putPaperResultApproval = ({ id }: ByID): Promise<SuccessResponse> =>
  client.put(`/api/v1/tests/paper-results/${id}/approve`);
const getPaperResultCountQuestion = ({ id }: ByID): Promise<TestPaperResultCountQuestion> =>
  client.get(`/api/v1/tests/paper-results/${id}/count-questions`);

const postInitTesting = ({ id }: ByID): Promise<TestingStudent> =>
  client.post(`/api/v1/tests/paper-results/${id}/testing`);
const getTesting = ({ paperId, paperAssignId }: { paperId: number; paperAssignId: number }): Promise<ByID> =>
  client.get(`/api/v1/tests/paper-results/${paperId}/check-testing/${paperAssignId}`);
const postPaperResultAnswer = ({ paperId, ...body }: TestPaperResultAnswerUpsertBody): Promise<TestQuestion> =>
  client.post(`/api/v1/tests/paper-results/${paperId}/answers`, body);
const postPaperResultSubmit = ({ id }: ByID): Promise<SuccessResponse> =>
  client.post(`/api/v1/tests/paper-results/${id}/submit`);
const fetchMyPaperResults = ({ id, ...body }: ByID & PaginateParams): Promise<PaginateResponse<TestPaperResult>> =>
  client.post(`/api/v1/tests/paper-results/${id}/me/histories`, body);
const getMyPaperResultAnswer = ({
  resultId,
  answerId,
}: {
  resultId: number;
  answerId: number;
}): Promise<TestingQuestion> => client.get(`/api/v1/tests/paper-results/${resultId}/answers/${answerId}/testing`);
const getPaperResultAnswer = ({
  resultId,
  answerId,
}: {
  resultId: number;
  answerId: number;
}): Promise<TestPaperResultAnswer> => client.get(`/api/v1/tests/paper-results/${resultId}/answers/${answerId}`);
const fetchPaperResultTracks = ({
  id,
  ...body
}: ByID & PaginateParams): Promise<PaginateResponse<TestPaperResultTrack>> =>
  client.post(`/api/v1/tests/paper-results/${id}/tracks/list`, body);
const fetchPaperQuestionStatistic = ({
  id,
  ...body
}: ByID & TestPaperQuestionStatisticSearchParams): Promise<PaginateResponse<TestQuestionStatistic>> =>
  client.post(`/api/v1/tests/paper-results/${id}/questions/statistic`, body);
const fetchPaperQuestionResultsStatistic = ({
  paperId,
  questionId,
  ...body
}: { paperId: number; questionId: number } & TestPaperQuestionStatisticSearchParams): Promise<
  PaginateResponse<TestPaperResult>
> => client.post(`/api/v1/tests/paper-results/${paperId}/questions/${questionId}/list`, body);

const paperService = {
  fetchAllPapers,
  fetchPapers,
  fetchMyPapers,
  getPaperInfo,
  addPaper,
  putPaperActive,
  putPaperDeactive,
  updatePaper,

  fetchPaperQuestions,
  searchPaperQuestions,
  addPaperQuestions,
  postMovePaperQuestionUp,
  postMovePaperQuestionDown,
  putPaperQuestionPoint,
  deletePaperQuestion,

  fetchPaperAssigns,
  addPaperAssign,
  removePaperAssign,

  getPaperAssesment,
  postPaperAssessment,

  fetchPaperResults,
  getPaperResultInfo,
  fetchPaperResultAnswers,
  putPaperResultAnswerMark,
  putPaperResultApproval,
  getPaperResultCountQuestion,

  getTesting,
  postInitTesting,
  postPaperResultAnswer,
  postPaperResultSubmit,
  fetchMyPaperResults,
  getMyPaperResultAnswer,
  getPaperResultAnswer,
  fetchPaperResultTracks,

  fetchPaperQuestionStatistic,
  fetchPaperQuestionResultsStatistic,
};

export default paperService;
