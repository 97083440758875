import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { queryClient, registerService } from 'services';

type Props = PopupController & {
  register: Register;
  classroomId?: number;
};

const PopupRegisterReserveConfirm = ({ register, classroomId, onClose, onSuccess }: Props) => {
  const { mutate: registerReserve, isLoading } = useMutation(registerService.registerReserve, {
    onSuccess: () => {
      enqueueSnackbar('Bảo lưu khóa học thành công');
      queryClient.invalidateQueries(['registerService.fetchMyRegisters']);
      queryClient.invalidateQueries(['registerService.fetchMyReserves']);
      onSuccess!();
    },
  });

  const handleClickSubmit = () => {
    registerReserve({
      id: register.id,
      classroomId: classroomId ?? null,
    });
  };
  return (
    <>
      <DialogTitle>Xác nhận bảo lưu</DialogTitle>

      <DialogContent>
        <div className='text-center'>
          Bạn có chắc chắn muốn bảo lưu không? Sau khi bảo lưu thì bạn sẽ không nhìn thấy tài liệu của lớp{' '}
          {register.classroom?.name}, vui lòng xem các tài liệu của lớp bạn đã đăng ký trước đây
        </div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupRegisterReserveConfirm;
