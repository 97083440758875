import { Pagination } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { paperService } from 'services';
import CardPaper from './CardPaper';
import { useEffect, useState } from 'react';
import { useSearch } from 'hooks';

type Props = {
  testStatus?: number;
};

const CardPaperList = ({ testStatus }: Props) => {
  const [page, setPage] = useState(1);
  const { dataSearch, onSearchChange } = useSearch({ size: 5 });
  const { data, isFetching } = useQuery(
    ['registerService.fetchMyRegisters', dataSearch],
    () => paperService.fetchMyPapers({ ...dataSearch, testStatus: testStatus }),
    { keepPreviousData: true },
  );

  const { list: items = [], total = 0 } = data ?? {};
  useEffect(() => {
    onSearchChange({ ...dataSearch, testStatus: testStatus, page: page });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      <div className='flex flex-col space-y-6'>
        {items.map((paper, index) => (
          <CardPaper key={index} paper={paper} />
        ))}
        {!isFetching && items.length > 0 && (
          <Pagination
            variant='outlined'
            shape='rounded'
            count={(total ?? 0) % 5 === 0 ? (total ?? 0) / 5 : Math.floor((total ?? 0) / 5) + 1}
            page={page}
            onChange={(_event: React.ChangeEvent<unknown>, value: number) => {
              setPage(value);
            }}
          />
        )}
      </div>
      {!isFetching && items.length === 0 && (
        <div className='text-center font-bold'>HIỆN TẠI BẠN CHƯA CÓ BÀI TEST NÀO</div>
      )}
    </>
  );
};

export default CardPaperList;
