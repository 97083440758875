import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, InputLabel } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { fileService, queryClient, studentService } from 'services';
import { formatDateTime } from 'utils/common';

type InfoProps = {
  label?: React.ReactNode;
  value?: React.ReactNode;
};

const GridInfo = ({ label, value }: InfoProps) => {
  return (
    <div>
      {label && <span>{label}: </span>}
      <span className='font-bold'>{value ?? ''}</span>
    </div>
  );
};

type Props = PopupController & {
  register: Register;
};

const PopupOwnerEvaluation = ({ register, onClose }: Props) => {
  const { data: student } = useQuery(
    ['studentService.geMyEvaluationInfo', register.studentId],
    () => studentService.geMyEvaluationInfo({ id: register.studentId }),
    {
      enabled: register.studentId > 0,
    },
  );

  const fileHashId = student?.certificateFileHash ?? '';

  const { data: imageData } = useQuery(
    ['fileService.downloadFile', fileHashId],
    () => fileService.downloadFile(fileHashId!),
    {
      keepPreviousData: true,
      enabled: fileHashId !== '',
    },
  );

  const { mutate: postPublicMyEvaluation, isLoading: isLoadingPublic } = useMutation(
    studentService.postPublicMyEvaluation,
    {
      onSuccess: () => {
        enqueueSnackbar('Public đánh giá thành công');
        queryClient.invalidateQueries(['registerService.fetchMyRegisters']);
        onClose();
      },
    },
  );

  const { mutate: postPrivateMyEvaluation, isLoading: isLoadingUnPublic } = useMutation(
    studentService.postPrivateMyEvaluation,
    {
      onSuccess: () => {
        enqueueSnackbar('UnPublic đánh giá thành công');
        queryClient.invalidateQueries(['registerService.fetchMyRegisters']);
        onClose();
      },
    },
  );

  const handleClickPublic = () => {
    postPublicMyEvaluation({ id: register.studentId });
  };

  const handleClickUnPublic = () => {
    postPrivateMyEvaluation({ id: register.studentId });
  };

  return (
    <>
      <DialogTitle>Đánh giá học viên</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <GridInfo label='Khóa học đăng ký' />
          </Grid>
          <Grid item md={8}>
            <GridInfo value={register.course.name} />
          </Grid>
          <Grid item md={4}>
            <GridInfo label='Ngày đăng ký' />
          </Grid>
          <Grid item md={8}>
            <GridInfo value={formatDateTime(register.registerAt)} />
          </Grid>
          <Grid item md={4}>
            <GridInfo label='Kết quả' />
          </Grid>
          <Grid item md={8}>
            <GridInfo value={student?.passLevel?.name} />
          </Grid>
          <Grid item md={4}>
            <GridInfo label='Chi tiết đánh giá' />
          </Grid>
          <Grid item md={8}>
            <GridInfo value={student?.detail} />
          </Grid>
          <Grid item md={4}>
            <GridInfo label='Link bài test' />
          </Grid>
          <Grid item md={8}>
            <div>
              {student?.testLink && (
                <Link to={student?.testLink} target='_blank' className='text-primary-main hover:text-primary-dark'>
                  {student?.testLink}
                </Link>
              )}
            </div>
          </Grid>
          <Grid item md={4}>
            <GridInfo label='Chứng chỉ' />
          </Grid>
          <Grid item md={8}>
            {fileHashId && (
              <>
                <div>
                  <img
                    src={`${window.URL.createObjectURL(new Blob([imageData!]))}`}
                    alt='Ảnh chứng chỉ'
                    loading='lazy'
                  />
                </div>
                <div className='mt-2'>
                  <a
                    target='_blank'
                    download={student?.certificateFileName}
                    href={`${window.URL.createObjectURL(new Blob([imageData!]))}`}
                  >
                    <LoadingButton variant='outlined' size='small' onClick={() => {}}>
                      Tải chứng chỉ
                    </LoadingButton>
                  </a>
                </div>
              </>
            )}
          </Grid>
          <Grid item md={4}>
            <GridInfo label='Trạng thái public đánh giá' />
          </Grid>
          <Grid item md={8}>
            {student?.isPublic === 0 && (
              <InputLabel color='error' className='font-bold'>
                KHÔNG PUBLIC (Không cho người khác tra cứu)
              </InputLabel>
            )}
            {student?.isPublic === 1 && (
              <InputLabel color='info' className='font-bold'>
                PUBLIC (Cho người khác tra cứu)
              </InputLabel>
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        {student?.isPublic === 0 && student?.status === 'EVALUATED' && (
          <LoadingButton variant='contained' color='success' loading={isLoadingPublic} onClick={handleClickPublic}>
            Public đánh giá
          </LoadingButton>
        )}
        {student?.isPublic === 1 && student?.status === 'EVALUATED' && (
          <LoadingButton variant='contained' loading={isLoadingUnPublic} onClick={handleClickUnPublic}>
            HUỶ Public đánh giá
          </LoadingButton>
        )}
      </DialogActions>
    </>
  );
};

export default PopupOwnerEvaluation;
