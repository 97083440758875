import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TextFieldNumber, TextFieldSelect } from 'components/common';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { categoryService, paperService, queryClient } from 'services';

type Props = PopupController & {
  paper?: TestPaper;
};

const PopupPaperUpsert = ({ paper, onClose }: Props) => {
  const { control, handleSubmit } = useForm<PaperCreateBody>();
  const { data: dataTestObject } = useQuery(['categoryService.getAllTestObjects'], () =>
    categoryService.getAllTestObjects(),
  );

  const { mutate: addPaper, isLoading: isLoadingAdd } = useMutation(paperService.addPaper, {
    onSuccess: () => {
      enqueueSnackbar('Thêm mới bài test thành công');
      queryClient.invalidateQueries(['paperService.fetchPapers']);
      onClose();
    },
  });

  const { mutate: updatePaper, isLoading: isLoadingUpdate } = useMutation(paperService.updatePaper, {
    onSuccess: () => {
      enqueueSnackbar('Câp nhật bài test thành công');
      queryClient.invalidateQueries(['paperService.fetchPapers']);
      onClose();
    },
  });

  const handleClickSubmitAdd = () => {
    handleSubmit((values) => {
      addPaper({
        ...values,
      });
    })();
  };

  const handleClickSubmitUpdate = () => {
    handleSubmit((values) => {
      updatePaper({
        ...values,
        id: paper!.id,
      });
    })();
  };

  return (
    <>
      {!paper && <DialogTitle>Thêm mới bài test</DialogTitle>}
      {paper && <DialogTitle>Cập nhật bài test</DialogTitle>}

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name='name'
              defaultValue={paper?.name ?? ''}
              control={control}
              rules={{
                required: 'Tên bài test là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label='Tên bài test'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Controller
              name='objectId'
              defaultValue={paper?.objectId ?? 0}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextFieldSelect
                  {...field}
                  fullWidth
                  label='Đối tượng phù hợp'
                  error={!!error}
                  helperText={error?.message}
                >
                  {(dataTestObject?.list ?? []).map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextFieldSelect>
              )}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <Controller
              name='needApproveResult'
              defaultValue={paper?.needApproveResult === 1}
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControlLabel
                  key={1}
                  label='Duyệt chấm điểm'
                  control={<Checkbox {...field} checked={field.value ?? false} color='primary' />}
                />
              )}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <Controller
              name='isPublic'
              defaultValue={paper?.isPublic === 1}
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControlLabel
                  key={1}
                  label='Public'
                  control={<Checkbox {...field} checked={field.value ?? false} color='primary' />}
                />
              )}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Controller
              name='minute'
              defaultValue={paper?.minute}
              control={control}
              rules={{
                required: 'Thời gian làm bài là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextFieldNumber
                  {...field}
                  fullWidth
                  required
                  label='Thời gian làm bài (phút)'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Controller
              name='maxTime'
              defaultValue={paper?.maxTime}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextFieldNumber
                  {...field}
                  fullWidth
                  label='Tối đa (lần)'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Controller
              name='expiredDay'
              defaultValue={paper?.expiredDay}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextFieldNumber
                  {...field}
                  fullWidth
                  label='Thời hạn làm bài (ngày)'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='image'
              defaultValue={paper?.image}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField {...field} fullWidth label='Ảnh đại diện' error={!!error} helperText={error?.message} />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        {!paper && (
          <LoadingButton variant='contained' loading={isLoadingAdd} onClick={handleClickSubmitAdd}>
            Thêm mới
          </LoadingButton>
        )}
        {paper && (
          <LoadingButton variant='contained' loading={isLoadingUpdate} onClick={handleClickSubmitUpdate}>
            Cập nhật
          </LoadingButton>
        )}
      </DialogActions>
    </>
  );
};

export default PopupPaperUpsert;
