import { client } from './axios';

const postTestQuestionGroup = (body: TestQuestionGroupCreateBody): Promise<TestQuestionGroup> =>
  client.post(`/api/v1/tests/questions/groups`, body);
const putTestQuestionGroup = ({ id, ...body }: TestQuestionGroupUpdateBody): Promise<TestQuestionGroup> =>
  client.put(`/api/v1/tests/questions/groups/${id}`, body);

const fetchTestQuestions = (body: TestQuestionSearchParams): Promise<PaginateResponse<TestQuestion>> =>
  client.post(`/api/v1/tests/questions/list`, body);
const getTestQuestionInfo = ({ id }: ByID): Promise<TestQuestion> => client.get(`/api/v1/tests/questions/${id}`);
const postTestQuestion = (body: TestQuestionCreateBody): Promise<TestQuestion> =>
  client.post(`/api/v1/tests/questions`, body);
const putTestQuestion = ({ id, ...body }: TestQuestionUpdateBody): Promise<SuccessResponse> =>
  client.put(`/api/v1/tests/questions/${id}`, body);
const deleteTestQuestion = ({ id }: ByID) => client.delete(`/api/v1/tests/questions/${id}`);
const postTestQuestionMoveUp = ({ id }: ByID): Promise<SuccessResponse> =>
  client.post(`/api/v1/tests/questions/${id}/up`);
const postTestQuestionMoveDown = ({ id }: ByID): Promise<SuccessResponse> =>
  client.post(`/api/v1/tests/questions/${id}/down`);

const testQuestionService = {
  postTestQuestionGroup,
  putTestQuestionGroup,

  fetchTestQuestions,
  getTestQuestionInfo,
  postTestQuestion,
  putTestQuestion,
  deleteTestQuestion,
  postTestQuestionMoveUp,
  postTestQuestionMoveDown,
};

export default testQuestionService;
