import { Container, Grid, IconButton, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { paperService } from 'services';
import { PaperResultMark, ResultQuestionView } from './components';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { formatDateTime, formatNumber } from 'utils/common';
import { adminRoute } from 'routes';
import { CheckCircle, Close, EditNote, HourglassBottom, HourglassEmpty } from '@mui/icons-material';

type InfoProps = {
  label: React.ReactNode;
  value?: React.ReactNode;
};

const GridInfo = ({ label, value }: InfoProps) => {
  return (
    <div>
      <span>{label}: </span>
      <span className='font-bold'>{value ?? ''}</span>
    </div>
  );
};

const PaperResultView = () => {
  const navigate = useNavigate();
  const { id, questionIndex } = useParams();
  const [currentAnswer, setCurrentAnswer] = useState<TestPaperResultAnswer | undefined>();
  const [questionAnswerIndex, setQuestionAnswerIndex] = useState(0);
  const [mark, setMark] = useState<number | undefined>();

  const { data } = useQuery(
    [`paperService.getPaperResultInfo_${id}`],
    () => paperService.getPaperResultInfo({ id: +id! }),
    {
      keepPreviousData: true,
    },
  );

  const { answers: items = [] } = data ?? {};

  useEffect(() => {
    if (questionAnswerIndex >= 0 && questionAnswerIndex < items.length) {
      const curQuestion = items[questionAnswerIndex];
      if (data?.id) {
        paperService
          .getPaperResultAnswer({
            resultId: data?.id ?? 0,
            answerId: curQuestion.id,
          })
          .then((res) => {
            setCurrentAnswer(res);
          });
      } else {
        setCurrentAnswer(curQuestion);
      }
    }
    setMark(data?.point);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, questionAnswerIndex]);

  useEffect(() => {
    if (questionAnswerIndex >= 0 && questionAnswerIndex < (data?.answers ?? []).length) {
      setCurrentAnswer((data?.answers ?? [])[questionAnswerIndex]);
    }
    setMark(data?.point);
  }, [data, questionAnswerIndex]);

  useEffect(() => {
    if (questionIndex && Number.isFinite(+questionIndex)) {
      setQuestionAnswerIndex(+questionIndex!);
    }
  }, [questionIndex]);

  const onSaveMark = (addMark: number) => {
    setMark((mark ?? 0) + addMark);
  };

  const backList = () => {
    navigate(adminRoute.paperResultList.path);
  };
  return (
    <>
      <Container maxWidth='xl' className='py-6'>
        <Grid container spacing={3}>
          <Grid item xs={12} md={10}>
            <Typography variant='h4' color='primary' className='mb-10 text-center'>
              {data?.paper?.name}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant='h4'>
                  Điểm: {formatNumber(mark)}/{formatNumber(data?.maxPoint)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={data?.classroom ? 6 : 12} className='mt-1'>
                <GridInfo label='Học viên' value={data?.user?.fullName} />
              </Grid>
              {data?.classroom && (
                <Grid item xs={12} md={6}>
                  <GridInfo label='Lớp học' value={data?.classroom?.code + ' - ' + data?.classroom?.name} />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <GridInfo label='Thời gian bắt đầu' value={data?.createdAt ? formatDateTime(data?.createdAt) : ''} />
              </Grid>
              <Grid item xs={12} md={6}>
                <GridInfo label='Thời gian kết thúc' value={data?.savedAt ? formatDateTime(data?.savedAt) : ''} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={10}>
            <Typography variant='h4' color='primary' className='mb-6 mt-6 text-center'>
              <span>NỘI DUNG BÀI LÀM</span>
            </Typography>
            {currentAnswer && <ResultQuestionView answer={currentAnswer} />}
            {currentAnswer && currentAnswer.questionType === 'ESSAY' && data?.approvedStatus !== 1 && (
              <PaperResultMark answer={currentAnswer} onSaveMark={onSaveMark} />
            )}
          </Grid>
          <Grid item xs={12} md={2}>
            <List>
              {items.map((answer, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    sx={{
                      height: '2rem',
                      border: 0,
                      '&.Mui-selected': {
                        backgroundColor: '#2e8b57',
                        color: 'white',
                      },
                      '&.Mui-focusVisible': {
                        backgroundColor: '#eceff1',
                        color: 'white',
                      },
                      '&.Mui-selected:hover': {
                        backgroundColor: '#ddfdde',
                        color: 'black',
                      },
                      ':hover': {
                        backgroundColor: '#90caf9',
                        color: 'white',
                      },
                    }}
                    onClick={() => setQuestionAnswerIndex(index)}
                    selected={answer.id === currentAnswer?.id}
                  >
                    {answer.questionType === 'ESSAY' ? (
                      <ListItemText primary={'Câu ' + (index + 1) + ' (TL)'} />
                    ) : (
                      <ListItemText primary={'Câu ' + (index + 1)} />
                    )}
                  </ListItemButton>
                  <div className='items-end'>
                    {answer.isMarked && (
                      <IconButton
                        edge='end'
                        aria-label='Câu hỏi đã chấm điểm tự luận'
                        title='Câu hỏi đã chấm điểm tự luận'
                        color='info'
                      >
                        <EditNote />
                      </IconButton>
                    )}
                    {answer.isCorrect === -1 && (
                      <IconButton edge='end' aria-label='Câu hỏi chưa làm' title='Câu hỏi chưa làm' color='warning'>
                        <HourglassEmpty />
                      </IconButton>
                    )}
                    {answer.isCorrect === 0 && (
                      <IconButton edge='end' aria-label='Câu hỏi làm sai' title='Câu hỏi làm sai' color='error'>
                        <Close />
                      </IconButton>
                    )}
                    {answer.isCorrect === 1 && (
                      <IconButton
                        edge='end'
                        aria-label='Câu hỏi làm đúng một phần'
                        title='Câu hỏi làm đúng một phần'
                        color='primary'
                      >
                        <HourglassBottom />
                      </IconButton>
                    )}
                    {answer.isCorrect === 2 && (
                      <IconButton edge='end' aria-label='Câu hỏi làm đúng' title='Câu hỏi làm đúng' color='success'>
                        <CheckCircle />
                      </IconButton>
                    )}
                  </div>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
        <div className='mt-2 flex justify-center space-x-3'>
          <LoadingButton variant='contained' onClick={backList}>
            Quay lại
          </LoadingButton>
        </div>
      </Container>
    </>
  );
};

export default PaperResultView;
